import React, { ReactElement, Suspense, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer, ErrorFallback, DefaultSpinner } from '@ocr-gateway/ui';
import {
  setMenu,
  Api,
  WSService,
  RoleChangeService,
  LANDING_PAGE_URL,
  navbarHeight,
  YoutrackWidget
} from '@ocr-gateway/shared';
import { useLocation } from 'react-router';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import TagManager from 'react-gtm-module';
import { ContentWrapper, Navbar, Routes, Menu, HostnameWrapper } from './components';
import { routes } from './constants';
import SessionTimeout from './components/SessionTimeout';
import ChangeRolePopup from './components/ChangeRolePopup';
import _ from 'lodash';

const AppMenuAndContent = () => {
  const menu = useSelector((state: any) => state.layout.menu);
  const { email, organization, firstname, lastname } = useSelector((state: any) => state.auth.user);
  const [autoPopulate, setAutoPopulate] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const trackResubmitForm = (e: any) => {
      console.log({ element: e.target });
      if (
        e.target.closest('button.button_e6d2.createAnother__d5e5.heightS_d519.primary_b2ec') ||
        e.target.matches('button.button_e6d2.createAnother__d5e5.heightS_d519.primary_b2ec')
      )
        setAutoPopulate(prev => !prev);
    };
    YoutrackWidget.appendToDom({
      email,
      username: `${firstname} ${lastname}`,
      organization: organization.name,
      currentUrl: `${window.location.href}`,
      cb: () => {
        const resubmitButton = document.querySelector('.formPopup__bc9a');
        resubmitButton?.addEventListener('click', trackResubmitForm);
      }
    });
    return () => {
      document.removeEventListener('click', trackResubmitForm);
    };
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(email) && !_.isEmpty(organization?.name)) {
      YoutrackWidget.initYouTrackWidget({
        email,
        username: `${firstname} ${lastname}`,
        organization: organization.name,
        currentUrl: `${window.location.href}`
      });
    }
  }, [email, organization, window.location.href, autoPopulate]);

  const setMenuExpanded = (isExpanded: boolean, width: string) => {
    dispatch(
      setMenu({
        isExpanded,
        width
      })
    );
  };

  const location = useLocation();
  useEffect(() => {
    document.getElementById('scroll').scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Menu expanded={menu.isExpanded} setExpanded={setMenuExpanded} />
      <ContentWrapper expanded={menu.isExpanded}>
        <Suspense fallback={<DefaultSpinner />}>
          <Routes routes={routes} />
        </Suspense>
      </ContentWrapper>
      <ToastContainer />
    </>
  );
};

const App: React.FC = (props): ReactElement => {
  const { user, isSupportRole } = useSelector((state: RootStateOrAny) => state.auth);
  const { pushInstruction } = useMatomo();
  const { enableLinkTracking } = useMatomo();
  const { trackPageView, trackEvent } = useMatomo();
  const dispatch = useDispatch();
  const { changeRoleWarningPopup } = useSelector((state: any) => state.popups);
  const userId = useSelector((state: any) => state.auth.user.id);

  React.useEffect(() => {
    Api.setDispatch({ dispatch });
    WSService.setDispatch({ dispatch });
    RoleChangeService.setDispatch({ dispatch });
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        if (userId) {
          const {
            id,
            firstname,
            lastname,
            organization: { id: orgId }
          } = user;

          await RoleChangeService.connect({
            orgId,
            userId: id,
            name: `${firstname} ${lastname}`,
            isSupport: isSupportRole,
            userDetails: user
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [userId, user.organization.id]);

  React.useEffect(() => {
    if (user.id) {
      const isInternalTraffic = user.email.endsWith('@urchinsys.com');

      pushInstruction('setUserId', user.email);
      trackPageView({
        customDimensions: [
          {
            id: 1,
            value: user.email
          },
          {
            id: 2,
            value: user.organization.name
          }
        ]
      });

      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          company: user.organization.name,
          email: user.email,
          ...(isInternalTraffic && { trafficType: 'internal' })
        }
      });
    }
  }, [user]);

  enableLinkTracking();

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HostnameWrapper>
          <Router>
            <Navbar logoUri={LANDING_PAGE_URL} height={navbarHeight} />
            <AppMenuAndContent />
          </Router>
          <SessionTimeout minutes={30} />
          {changeRoleWarningPopup.show && (
            <ChangeRolePopup minutes={1} show={changeRoleWarningPopup.show} isSimple={true} />
          )}
        </HostnameWrapper>
        <div
          id="youtrack-component"
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            zIndex: 9999
          }}
        ></div>
      </ErrorBoundary>
    </>
  );
};

export default App;
