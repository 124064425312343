import { css } from '@emotion/core';

export const globalStyles = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    border: 0;
  }
  code,
  var,
  kbd,
  samp,
  tt,
  dir,
  listing,
  plaintext,
  xmp,
  abbr,
  acronym,
  blockquote,
  input,
  textarea,
  q {
    hyphens: none;
  }
  button {
    padding: 0;
    margin: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  ol,
  ul {
    list-style: none;
  }
  img,
  video {
    max-width: 100%;
  }
  img {
    border-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:after,
  blockquote:before,
  q:after,
  q:before {
    content: '';
    content: none;
  }
  a {
    text-decoration: none;
  }
  input,
  textarea {
    font: inherit;
  }

  body {
    background: #f3f3f5;
    font-family: 'Roboto', Helvetica, Arial, Tahoma, Verdana, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  html {
    font-size: 100%;
    @media only screen and (max-width: 1481px) {
      font-size: 95%;
    }
  }

  #zohohc-asap-web-ticketform-field-productid,
  #zohohc-asap-web-ticketform-section-additional_information,
  #zohohc-asap-web-ticketform-field-priority,
  #zohohc-asap-web-ticketform-field-classification {
    display: none;
  }
  div[data-test='ring-popup form-popup'] {
    z-index: 9999 !important;
  }
  .embeddedForm__c211 {
    margin-left: 20px;
  }

  div[data-test-title='Client'] {
    display: none;
  }
  div[data-test-title='Site Url'] {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e6e9f2;
    margin-left: 14px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #60698b;
  }
`;
