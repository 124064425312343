import React, { memo, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import {
  Api,
  apiKeysHash,
  setMenu,
  setInviteOwnerPopup,
  useControlVisibility,
  API_DOCS_URL,
  PLEXTERA_STUDIO_URL,
  LANDING_PAGE_URL,
  COOKIE_PREFIX,
  actResetDocumentOutline
} from '@ocr-gateway/shared';
import { deepEqual, Navbar as NavigationBar } from '@ocr-gateway/ui';
import { InviteOwnerPopup } from './InviteOwnerPopup';

import {
  linkToAccountSettings,
  linkToAdminConsole,
  linkToApi,
  linkToApiWithHash,
  linkToDocuments,
  linkToFaxHistory,
  linkToGoogleDriveChannelEditPostfix,
  linkToGoogleDriveIntegrations,
  linkToWebhookFailedTransactionsPostfix,
  linkToWebhookFailedTransactionsPrefix,
  linkToAdminConsoleCompanies,
  linkToDownloaderJobs
} from '../../constants';

interface NavbarProps {
  height?: string;
  logoUri?: string;
  auth: any;
  notifications?: any;
  state?: any;
  menu: any;
}

enum NotificationType {
  DOCUMENT_FAILED = 'DOCUMENT_FAILED',
  DOCUMENT_UNASSIGNED = 'DOCUMENT_UNASSIGNED',
  WORKFLOW_FAILED = 'WORKFLOW_FAILED',
  GDRIVE_CRED_REVOKE = 'GDRIVE_CRED_REVOKE',
  DOWNLOADER_FAILED = 'DOWNLOADER_FAILED',
  INCORRECT_DOCUMENT_DATA = 'INCORRECT_DOCUMENT_DATA'
}

const NavbarComponent: React.FC<NavbarProps> = memo(
  ({
    height,
    dispatch,
    logoUri,
    auth,
    inviteOwner,
    notifications,
    currentOrganization,
    accessibleOrganizations,
    menu
  }: any): React.ReactElement => {
    const history = useHistory();
    const { pathname: pageUrl } = useLocation();
    const queryClient = useQueryClient();
    const {
      organization: { showInviteOwner }
    } = useControlVisibility();
    const isAdminConsolePage = pageUrl.startsWith(linkToAdminConsole);
    const showInviteUserButton =
      showInviteOwner && auth.isSupportRole && pageUrl !== linkToAdminConsoleCompanies;
    const showPlexteraStudioLink = !!Cookies.get(`from-plextera-studio${COOKIE_PREFIX}`);

    const navigation = [
      {
        linkTo: '/',
        text: 'My Dashboard'
      },
      ...(showPlexteraStudioLink
        ? [
            {
              simpleLink: PLEXTERA_STUDIO_URL,
              text: 'Plextera'
            }
          ]
        : []),
      {
        simpleLink: `${API_DOCS_URL}`,
        text: 'API'
      },
      {
        simpleLink: `${LANDING_PAGE_URL}/faq`,
        text: 'FAQs'
      },
      {
        simpleLink: `${LANDING_PAGE_URL}/blog`,
        text: 'Blog'
      },
      {
        simpleLink: `${LANDING_PAGE_URL}/contact-us/request-a-demo`,
        text: 'Contact Us'
      }
    ];

    const handleSignOutClick = (e: any) => {
      Api.logOut();
    };

    const handleAccountSettingsClick = (e: React.MouseEvent) => {
      history.push(linkToAccountSettings);
    };

    const handleAdminConsoleClick = () => {
      history.push(linkToAdminConsole);
    };

    const setApiKeysButton = ({ hash, pathname }: any) => {
      const isApiKeysHash = hash === apiKeysHash;
      const isApiPathname = pathname === linkToApi;
      const showButton = isApiPathname && !isApiKeysHash;
      setShowApiKeysButton(showButton);
    };

    const [showApiKeysButton, setShowApiKeysButton] = useState(false);

    useEffect(() => {
      const { hash, pathname } = history.location;
      setApiKeysButton({ hash, pathname });
      const unlisten = history.listen(location => {
        const { hash, pathname } = location;
        setApiKeysButton({ hash, pathname });
      });

      return () => {
        unlisten();
      };
    }, []);

    useEffect(() => {
      if (currentOrganization) {
        Api.getNotifications({ dispatch });
      }
    }, [currentOrganization]);

    const handleNotificationLinkClick = (notification: any) => {
      const { id, type, attributes } = notification;
      let url = '';
      let state: {};
      switch (type) {
        case NotificationType.DOCUMENT_FAILED:
        case NotificationType.DOCUMENT_UNASSIGNED:
        case NotificationType.WORKFLOW_FAILED:
          url = `${linkToDocuments}?status=failed&page=1&size=8`;
          break;
        case NotificationType.GDRIVE_CRED_REVOKE:
          url = `${linkToGoogleDriveIntegrations}${attributes.channelId}${linkToGoogleDriveChannelEditPostfix}`;
          state = {
            showRegrantAccess: true
          };
          break;
        case NotificationType.INCORRECT_DOCUMENT_DATA:
          url =
            attributes.status === 'DONE'
              ? `${linkToDocuments}?status=done&q=${attributes.id}&page=1&size=8&searchBy=ID`
              : `${linkToDocuments}?status=failed&page=1&size=8`;
          state = {
            dontDeleteQuery: true
          };
          break;
        case NotificationType.DOWNLOADER_FAILED:
          url = `${linkToDownloaderJobs}?jobId=${attributes.jobId}&jobName=${attributes.downloaderName}`;
          break;
        default:
          break;
      }
      if (url) {
        history.push(url, {
          ...state
        });
      }
      Api.acknowledgeNotification({ dispatch, id }).then();
    };

    const handleInviteUserClick = (e: React.MouseEvent) => {
      dispatch(
        setInviteOwnerPopup({
          ...inviteOwner,
          showWarning: false,
          showSuccess: false,
          show: true
        })
      );
    };

    const toggleMenuExpand = () => {
      dispatch(
        setMenu({
          ...menu,
          isExpanded: !menu.isExpanded
        })
      );
    };

    const onSwitchOrganization = (orgID: string) => {
      Api.switchOrganization({
        organizationId: orgID,
        refreshToken: auth.refreshToken,
        dispatch
      }).then(() => {
        Api.getUserInfo({ dispatch });
        queryClient.invalidateQueries('billingData');
        queryClient.invalidateQueries('invoices');
        dispatch(actResetDocumentOutline());
      });
    };

    const clearAllNotifications = (): void => {
      Api.acknowledgeAllNotifications().then(() => Api.getNotifications({ dispatch }));
    };

    return (
      <>
        <NavigationBar
          isAdminConsolePage={isAdminConsolePage}
          showInviteUserButton={showInviteUserButton}
          inviteUserButtonText="invite new owner"
          onInviteUserClick={handleInviteUserClick}
          onSignOutClick={handleSignOutClick}
          onSettingsClick={handleAccountSettingsClick}
          onAdminConsoleClick={handleAdminConsoleClick}
          linkComponent={NavLink}
          toggleMenuExpand={toggleMenuExpand}
          isMenuExpanded={menu.isExpanded}
          logoUri={logoUri}
          height={height}
          navigation={navigation}
          user={auth.user}
          notifications={notifications.results}
          onNotificationLinkClick={handleNotificationLinkClick}
          onSwitchOrganization={onSwitchOrganization}
          currentOrganization={currentOrganization}
          accessibleOrganizations={accessibleOrganizations}
          clearAllNotifications={clearAllNotifications}
        />
        <InviteOwnerPopup header="Invite New Owner User" />
      </>
    );
  },
  (prevProps: any, nextProps: any) => {
    const propsExtractor = ({ menu, auth, notifications, inviteOwner, currentOrganization }) => ({
      menu,
      auth,
      notifications,
      inviteOwner,
      currentOrganization
    });

    return deepEqual(propsExtractor(prevProps), propsExtractor(nextProps));
  }
);

function mapStateToProps(state: any) {
  return {
    auth: state.auth,
    currentOrganization: state.auth.user.organization,
    accessibleOrganizations: state.auth.user.accessibleOrganizations,
    menu: state.layout.menu,
    inviteOwner: state.popups.inviteOwner,
    notifications: state.notifications
  };
}

export const Navbar = connect(mapStateToProps)(NavbarComponent);
